<template>
  <div id="diagram-safari" class="part-component part-diagram">
    <div class="safari-diagram">
      <div class="layout-column stats-wrap averia">
        <div
          class="stats-lead stats-box layout-column parallax-item parallax-flex"
          data-depth="2.4"
        >
          <span class="stats-lead-1">Every night you spend at</span>
          <span class="stats-lead-2">Londolozi</span>
        </div>

        <div
          class="stats-box stats-stat layout-column parallax-item parallax-flex"
          id="stats-family"
          data-depth="2.6"
        >
          <div class="stats-intro stats-line">
            <span class="stats-intro-1">You are responsible for the</span>
            <span class="stats-intro-2">employment & welfare of</span>
          </div>
          <div class="layout-row stats-content layout-align-start-start">
            <div
              class="stats-no parallax-item parallax-relative"
              data-no="5"
              :data-depth="parallaxDepths.no"
            >
              0
            </div>
            <div class="stats-text layout-column">
              <span class="stats-line-1 stats-line">Employees</span>
              <span class="stats-line-2 stats-line"
                >and their 7 dependants</span
              >
              <div
                class="stats-icons ico red parallax-item parallax-relative"
                :data-depth="parallaxDepths.icons"
              >
                <span>GGGGG</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="stats-box stats-stat layout-column parallax-item parallax-flex"
          id="stats-rhino"
          data-depth="2.8"
        >
          <div class="stats-intro stats-line">
            <span class="stats-intro-1">You are helping to protect</span>
          </div>
          <div class="layout-row stats-content layout-align-start-start">
            <div
              class="stats-no parallax-item parallax-relative"
              data-no="6"
              :data-depth="parallaxDepths.no"
            >
              0
            </div>
            <div class="stats-text layout-column">
              <span class="stats-line-1 stats-line">Rhino</span>
              <div
                class="stats-icons ico green parallax-item parallax-relative"
                :data-depth="parallaxDepths.icons"
              >
                <span>EEE</span>
                <span>EEE</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="stats-box stats-stat layout-column parallax-item parallax-flex"
          id="stats-investment"
          data-depth="3"
        >
          <div class="stats-intro stats-line">
            <span class="stats-intro-1">Through the gwf you</span>
            <span class="stats-intro-2">support the education of</span>
          </div>
          <div class="layout-row stats-content layout-align-start-start">
            <div
              class="stats-no parallax-item parallax-relative"
              data-no="8"
              :data-depth="parallaxDepths.no"
            >
              0
            </div>
            <div class="stats-text layout-column">
              <span class="stats-line-1 stats-line">Children</span>
              <span class="stats-line-2 stats-line">and one adult</span>
              <div
                class="stats-icons ico pink parallax-item parallax-relative"
                :data-depth="parallaxDepths.icons"
              >
                <span>FFFFFFFF</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "DiagramSafari",
  components: {},
  mixins: [partComponent],
  props: ["show"],
  data() {
    return {
      parallaxDepths: {
        icons: 0.7,
        no: 0.4,
      },
    };
  },
  methods: {
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        ".stats-lead",
        0.7,
        {
          autoAlpha: 0,
          x: "+=30",
          ease: Power2.easeInOut,
        },
        0
      );

      const boxes = document.getElementsByClassName("stats-stat");
      const staggerSplit = 0.1;
      const staggerDuration = 0.4;
      let i = 1;

      for (let item of boxes) {
        let icons = item.querySelector(".stats-icons");
        let number = item.querySelector(".stats-no");
        let endCount = number.dataset.no;

        let tlBox = new TimelineMax();

        let iconsSplit = new SplitText(icons, {
          type: "chars",
          position: "relative",
        });
        let countUpDuration =
          iconsSplit.chars.length * staggerSplit + staggerDuration;

        let numberVal = {
          val: 0,
        };

        tlBox
          .from(
            item,
            0.7,
            {
              autoAlpha: 0,
              x: "+=30",
              ease: Power2.easeOut,
            },
            0
          )
          .staggerFrom(
            iconsSplit.chars,
            staggerDuration,
            {
              y: "50",
              autoAlpha: 0,
              ease: Linear.easeNone,
            },
            staggerSplit,
            0
          )
          .to(
            numberVal,
            countUpDuration,
            {
              val: endCount,
              roundProps: "val",
              ease: Linear.easeNone,
              onUpdate: function () {
                number.innerHTML = numberVal.val;
              },
            },
            0
          );

        tl.add(tlBox, i * 0.4);

        i++;
      }

      this.timeline = tl;

      //reverse
      const tlOut = new TimelineMax({
        paused: true,
      });

      tlOut.staggerTo(
        ".stats-box",
        0.7,
        {
          autoAlpha: 0,
          x: "+=30",
          ease: Power2.easeInOut,
        },
        0.3
      );

      this.timelineReverse = tlOut;
    },
  },
};
</script>

<style scoped>
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1023px) {
  .stats-wrap {
    font-size: 1rem;
  }
}
</style>
