<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="row layout-column">
        <div class="cols layout-row row-1 layout-align-center-start">
          <div class="col-1-1 layout-column col-padding">
            <section-title
              :partNo="part.id"
              :partTitle="part.title"
              data-depth="2.5"
              class="text-right parallax-item parallax-relative"
              :show="showTitle"
            ></section-title>
          </div>
          <div class="col-1-2 layout-column">
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="1.8"
              :textAlign="'left'"
              :timescale="1"
              >{{ data.meta.description }}</lead
            >
          </div>
        </div>
        <div class="cols layout-row row-2 layout-align-center-start">
          <div class="col-2-1 layout-column col-padding layout-align-start-end">
            <info-modal-box
              id="box-havens"
              :show="showInfoBoxes"
              class="parallax-item parallax-relative section-infobox"
              data-depth="3.2"
              :data="data.boxes.info_1"
            >
            </info-modal-box>
          </div>
          <div class="col-2-2 layout-row layout-align-start-start">
            <div class="col-2-2-1">
              <diagram-safari :show="showDiagram"></diagram-safari>
            </div>
            <div class="col-2-2-2 col-padding">
              <info-modal-box
                id="box-protect"
                :show="showInfoBoxes"
                class="parallax-item parallax-relative section-infobox"
                data-depth="3.4"
                :data="data.boxes.info_2"
              >
              </info-modal-box>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container fluid absolute">
      <decal
        class="absolute bottom-left"
        :show="showDecal"
        :image="data.images.rhino"
        :customTimeline="timelines.timelineDecal"
      ></decal>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{
        objectPosition: 'right bottom',
        objectFit: 'cover',
        width: '110%',
        bottom: '-5.5%',
      }"
      :mask="true"
      :maskOpacity="0.95"
      :timescaleReverse="2.8"
      :timescale="1.5"
    ></background-image-full>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import Lead from "@/components/ui/Lead.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";
import Decal from "@/components/ui/Decal.vue";

import DiagramSafari from "@/views/chapters/2/DiagramSafari.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-2-4",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    BackgroundImageFull,
    Lead,
    InfoModalBox,
    Decal,
    DiagramSafari,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 500],
        ["showTitle", 400],
        ["showDiagram", 1200],
        ["showDecal", 0],
        ["showInfoBoxes", 500],
        ["showLead", 1200],
      ],
      timelines: {
        timelineDecal: {
          duration: 1.6,
          animations: {
            scale: 0.85,
            autoAlpha: 0,
            y: "+=7%",
            x: "-=7%",
            ease: Power3.easeInOut,
          },
        },
        timelineLeadHeading: {
          start: 0,
          duration: 0.7,
          animations: {
            autoAlpha: 0,
            ease: Power1.easeInOut,
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.row-1,
.row-2 {
  margin-left: 5%;
}
.col-1-1 {
  padding-left: 0;
}
.col-1-2,
.col-2-2 {
  padding-left: 0.8rem;
}
.col-2-2 {
  margin-top: -5rem;
}
.col-2-2-2 {
  padding-right: 0;
}
.section-background {
  opacity: 0.65;
}
#box-protect {
  height: 0%;
}
/deep/ #box-protect h3 span {
  max-width: 6em;
}
.section-title {
  max-width: 4em;
  margin-bottom: 2.3rem;
  margin-top: 5%;
}
.part-lead {
  margin: 1.3em 0 0 0;
}

.container.fixed.fluid {
  z-index: 1;
}
.section-decal {
  bottom: -11.5% !important;
  left: -1% !important;
  max-width: 60vw;
}
/deep/ .section-decal img {
  max-height: 90vh;
}
.landscape .section-decal {
  max-width: 40vw;
}

/deep/ .part-lead .lead {
  max-width: 35em;
}

/deep/ .section-background-mask {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0.3) 80%,
    rgba(255, 255, 255, 0.5) 100%
  );
}
/deep/ .section-nav-mask .section-footer-mask {
  background: rgba(255, 255, 255, 0.5);
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .col-2-2 {
    padding-right: 1.5rem;
  }
  .col-2-2-1 {
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 1440px) {
  .section-decal {
    bottom: -7vw !important;
  }
  .portrait .section-decal {
    bottom: -10.5vw !important;
  }
  .col-2-2 {
    padding-right: 3.4rem;
  }
  .row-1,
  .row-2 {
    margin-left: 3%;
  }
  .col-2-2 {
    margin-top: -3.8rem;
  }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1200px) {
  .col-2-2 {
    padding-right: 2.5rem;
  }
  .row-1,
  .row-2 {
    margin-left: 0;
  }
  /deep/ .section-background-mask {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 40%,
      rgba(255, 255, 255, 0.3) 80%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
  /deep/ .section-nav-mask .section-footer-mask {
    background: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (max-width: 1024px) {
  .col-2-2 {
    margin-top: -3.3rem;
  }
}

@media screen and (max-width: 1023px),
  screen and (min-width: 992px) and (max-width: 1024px) and (orientation: portrait) {
  .col-2-2 {
    margin-top: 0rem;
    order: 1;
    width: 100%;
    justify-content: center;
    padding: var(--col-padding);
  }
  .section-title {
    margin: 0.378em 0 0.489em;
  }
  .row-2 {
    flex-direction: column;
  }
  .portrait .row-2 {
    padding-bottom: 5rem;
  }
  .col-2-1 {
    order: 2;
    align-items: center;
    width: 100%;
    padding: var(--col-padding);
  }
  .part-lead {
    margin: 0;
  }
  .row-1 {
    align-items: center;
  }
  /deep/ .part-lead .lead {
    max-width: 26em;
  }
  .section-title {
    margin-left: 7.2rem;
  }
}

@media screen and (max-width: 991px) {
  .row-1 {
    padding: var(--col-padding);
    flex-direction: column;
    margin-bottom: 1.4rem;
  }
  .col-1-1 {
    padding: 0;
  }
  .section-title {
    margin-left: 0;
  }
  /deep/ .section-decal img {
    max-height: none;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 639px) {
}

@media screen and (max-width: 600px) {
  .col-2-2 {
    flex-direction: column;
    align-items: center;
  }
  .col-2-2-2 {
    padding: 0;
  }
}

@media screen and (max-width: 520px) {
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 440px) {
  /deep/ .part-lead .lead {
    max-width: 100%;
  }
  .part-lead,
  .section-title {
    margin-right: var(--col-padding-adjust);
  }
  .section-decal {
    max-width: 70vw;
  }
}

@media screen and (max-width: 420px) {
}

@media screen and (max-width: 390px) {
}
</style>
